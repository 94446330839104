.App {
  text-align: center;
  height: 100dvh;
  font-family: "DM Sans";
  font-optical-sizing: auto;
  color: white;
  background-color: black;
  display: flex;
  flex-direction: row;
  position: relative;
}

.gradient {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 100%, rgb(33 217 229 / 25%), transparent);
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.header {
  width: 100%;
  font-weight: 600;
  font-size: 24px;
  text-align: right;
  padding: 20px;
  box-sizing: border-box;
}

.currentProject {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.face {
  flex: 1;
  background: black url(../public/face.jpg) no-repeat 0 0 /contain;
}
.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.bf {
  width: min(300px, 100vw);
  height: 37px;
  background: url(../public/bolt-type.png) no-repeat 0 0 /contain;
}

.copyright {
  font-weight: 300;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}
.name {
  width: 100px;
  height: 70px;
  margin-left: -20px;
}

@media screen and (max-width: 600px) {
  .App {
    background: black url(../public/face.jpg) no-repeat 0 0 / 50%;
  }
  .face {
    display: none;
  }
}